import { createStore } from 'vuex'

export default createStore({
	state: {
		token: {}, //Authorization
	},
	getters: {

	},
	mutations: {
		// 定义mutations，用于修改状态(同步)
		updateToken(state, payload) {
			state.token = payload
		},
	},
	actions: {
		updateToken(context, payload) {
					setTimeout(() => {
						context.commit('updateToken', payload)
					}, 2000)
				}
	},
	modules: {
	}
})